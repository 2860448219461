// project import
import pages from "./pages";
import dashboard from "./dashboard";
import utilities from "./utilities";
import support from "./support";
import reports from "./reports";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, reports, pages, utilities, support],
  //   items: [reports],
};

export default menuItems;
