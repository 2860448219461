// assets
import { ChromeOutlined, QuestionOutlined, CopyFilled } from '@ant-design/icons';

// icons
const icons = {
    ChromeOutlined,
    QuestionOutlined,
    CopyFilled
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const support = {
    id: 'report',
    title: 'Report',
    type: 'group',
    children: [
        {
            id: 'daily-report',
            title: 'Daily Report',
            type: 'item',
            url: '/daily-report',
            icon: icons.CopyFilled
        },
        {
            id: 'monthly-report',
            title: 'Monthly report',
            type: 'item',
            url: '/monthly-report',
            icon: icons.QuestionOutlined,
           
        }
    ]
};

export default support;
